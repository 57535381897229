import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
            <ul className="icons">
            <li><a target="_blank" rel="noopener noreferrer" href="https://appseed.us?ref=react-themes" className="icon alt fa-rocket"><span className="label">AppSeed</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://blog.appseed.us" className="icon alt fa-rss"><span className="label">Blog</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://github.com/react-theme/index" className="icon alt fa-github"><span className="label">GitHub</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/webappseed" className="icon alt fa-twitter"><span className="label">Twitter</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/webappseed" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>
            <li><a target="_blank" rel="noopener noreferrer" href="https://instagram.com/webappseed" className="icon alt fa-instagram"><span className="label">Instagram</span></a></li>
            </ul>
            <ul className="copyright">
                <li>&copy; AppSeed{' - '}<a href="https://appseed.us?ref=react-themes">#FullStack power for #web development</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
